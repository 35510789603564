<template>
  <!-- Desc: Adgroups view at the campaign level of campaign details -->
  <div
    class="card min-height-card u-flex-direction-column u-width-100 u-display-flex"
  >
    <div v-if="adjustBids">
      <div class="u-font-size-6 u-spacing-p-l">
        <div class="u-spacing-pb-xs u-text-case-upper u-font-weight-600">
          ADJUST BID ({{ selections.length }} AD GROUP{{
            selections.length > 1 ? `'s` : ''
          }}
          selected)
        </div>
        <radioSelectionWithInput
          class="u-spacing-pt-m"
          :radio-config="bidChangeConfig"
          default-radio-selected="set_new_bid"
          :default-input-value="defaultBidValue"
          :error-message="errorMessage"
          :show-input-title="showInputTitle"
          @onUpdate="onBidUpdates"
        />
      </div>
      <div
        class="u-spacing-pv-m u-border-top u-border-color-grey-xxx-light u-border-width-s"
      >
        <footerButtons
          :button-min-width="'u-min-width-100px'"
          :disable-left-btn="
            !(bidValue && bidValue.inputValue && bidValue.inputValue.length) ||
            errorMessage.length > 0
          "
          class="u-spacing-ph-l"
          :hollow-hover-button-left="false"
          left-button-type="filled"
          btn-left-text="Apply"
          :button-size="'s'"
          @onClickBtnRight="closeActionBar"
          @onClickBtnLeft="onSaveBid"
        />
      </div>
    </div>
    <div
      v-if="confirmStatusChange"
      class="u-spacing-pt-l"
    >
      <component
        :is="confirmStatusChange.component"
        v-bind="confirmStatusChange.props(this)"
      />
    </div>
    <div
      v-if="selections.length && !adjustBids && !confirmStatusChange"
      class="u-spacing-pt-l"
    >
      <actions-bar
        :close-fn="closeActionBar"
        :selections="selections"
        :recommendation-type="''"
        :passed-actions="allowedActions"
        :no-action-message="tabConfig.noActionMessage"
        @open-workspace="openActionsWorkspace"
      />
    </div>
    <tableWrapper
      :table-data-component-key="tableDataComponentKey"
      :class="selections.length && 'campaign_details_table_header_padding'"
      :date-filter="dateFilter"
      :tab-metadata="tabMetadata"
      :widget="widget"
      :header-options="headerOptions"
      :context="context"
      :entity-config="entityConfig"
    />
  </div>
</template>
<script>
import Vue from 'vue';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import { cloneDeep } from 'lodash';
import radioSelectionWithInput from '@/components/pages/instacart/campaigns/campaigns/adjustBudget/radioSelectionWithInput.vue';
const tableWrapper = () =>
  import('@/pages/entity_details/common_components/tableWrapper.vue');
const actionsBar = () => import('@/components/widgets/actionsBar.vue');
const footerButtons = () =>
  import('@/pages/entity_details/common_components/footerButtons.vue');
const confirmStatusChangeComponent = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/approveRec.vue'
  );
const iconTextActions = () =>
  import('@/components/widgets/iconTextActions.vue');

export default {
  components: {
    tableWrapper,
    actionsBar,
    radioSelectionWithInput,
    footerButtons
  },
  props: {
    dateFilter: {
      default: () => {},
      type: Object
    },
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    }
  },
  data() {
    const context = this;
    return {
      bidValue: null,
      errorMessage: '',
      showInputTitle: false,
      bidChangeConfig: [
        {
          title: 'Set new bid',
          type: 'input',
          value: 'set_new_bid'
        },
        {
          title: 'Increase bid',
          value: 'increase_bid',
          type: 'dropdown',
          dropdown: [
            {
              label: 'Change by percentage',
              value: 'percentage'
            },
            {
              label: 'Change absolute value',
              value: 'absolute_value'
            }
          ]
        },
        {
          title: 'Decrease bid',
          value: 'decrease_bid',
          type: 'dropdown',
          dropdown: [
            {
              label: 'Change by percentage',
              value: 'percentage'
            },
            {
              label: 'Change absolute value',
              value: 'absolute_value'
            }
          ]
        }
      ],
      getState: null,
      selections: [],
      adjustBids: null,
      stateChangeRequestPayload: null,
      bidChangeRequestPayload: null,
      updateMetadata: null,
      tableGridAPI: null,
      context,
      actionIdMethodMap: {},
      widget: {},
      allowedActions: [
        {
          icon: 'task-status',
          title: 'EDIT STATUS',
          id: 'editStatus',
          dropDown: true,
          dropDownOptions: [
            { title: 'Enable', value: 'enabled', color: 'u-color-green-base' },
            { title: 'Pause', value: 'paused', color: 'u-color-orange-base' },
            { title: 'Archive', value: 'archived', color: 'u-color-red-base' }
          ]
        },
        {
          icon: 'dollar',
          title: 'Adjust Bid',
          id: 'adjustBids'
        }
      ],
      tabConfig: null,
      confirmStatusChange: null
    };
  },
  computed: {
    defaultBidValue() {
      return this.selections.length === 1
        ? this.selections[0]?.data?.instacart_cm_adgroup_default_bid || 0
        : 0;
    },
    tableDataComponentKey() {
      return this.$store.getters[
        this.tabConfig?.apiConfigs?.getTableDataComponentKeyGetter
      ];
    },
    campaignInfo() {
      return this.$store.getters[this.getState];
    },
    headerOptions() {
      const obj = {};
      const that = this;
      let headerOptions = [
        {
          ui_component: 'rb-button',
          props: {
            clickFn: that.createAddGroup,
            text: 'Create Ad Group',
            type: 'filled'
          },
          class: 'u-width-15 u-spacing-mr-xl'
        }
      ];
      // As of now hiding the button for SD - Audience targeting
      if (
        (this.$store.getters.getRetailer === 'amazon' &&
          this.campaignInfo?.data?.targetingType === 'auto') ||
        this.tabConfig?.disableCreateAdgroup ||
        this.campaignInfo?.data?.typeTargeting === 'audience_targeting' ||
        (this.$route?.params?.entityType === 'sb' &&
          this.$store.getters.getRetailer === 'amazon')
      ) {
        headerOptions = [];
      }
      if (this.tabMetadata?.widgets[0]) {
        obj[this.tabMetadata.widgets[0]] = {
          props: {
            headerOptions: headerOptions,
            enableManageColumns: !this.selections.length,
            headerContainerGridStyles: {
              display: this.selections.length ? 'none' : 'flex',
              'align-items': 'center',
              'justify-content': headerOptions.length
                ? 'space-between'
                : 'flex-end'
            },
            searchParams: {
              show: false
            },
            ...(this.tabConfig?.disableRowSelection
              ? []
              : {
                  rowSelection: {
                    mode: 'multiple',
                    onSelectionChanged: this.handleSelectionChange
                  }
                }),
            rowHeight: 60
          }
        };
      }
      return obj;
    }
  },
  watch: {
    campaignInfo() {
      this.updateAllowedActions();
    }
  },
  created() {
    const tabConfig = this.entityConfig.tabs[this?.tabMetadata?.component];
    this.tabConfig = tabConfig;
    this.getState = tabConfig?.apiConfigs?.getter;
    this.stateChangeRequestPayload = tabConfig?.stateChange?.requestPayload;
    this.bidChangeRequestPayload = tabConfig?.bidChange?.requestPayload;
    this.updateMetadata = tabConfig?.apiConfigs?.updateAction;
    let orderByListDimension = [];
    if (this.$store.getters.getRetailer === 'amazon') {
      orderByListDimension = [
        {
          dimension: 'ams_cm_adgroup_spend',
          direction: 'DESC'
        }
      ];
    } else if (this.$store.getters.getRetailer === 'instacart') {
      this.showInputTitle = true;
      orderByListDimension = [
        {
          dimension: 'instacart_cm_adgroup_spend',
          direction: 'DESC'
        }
      ];
    } else {
      orderByListDimension = [];
    }
    this.widget = {
      ':widget': this.tabMetadata.widgets[0],
      ':orderByList': orderByListDimension,
      ':dimensionNameValueList': [],
      keepExistingDimensionNameValueList: true,
      replaceEntityInOperationsObject: {
        ':system': `campaign_${this.entityId}`
      },
      replaceEntityInDimensionNameValueList: {
        ':campaign_id': this.entityId,
        ':ad_group_id': this.entityId
      }
    };
    this.updateAllowedActions();
    Vue.component('confirmStatusChangeComponent', confirmStatusChangeComponent);
    Vue.component('iconTextActions', iconTextActions);
  },
  methods: {
    getPayloadArr(selections, dropDownSelection) {
      const payloadArr = [];
      for (const selection of selections) {
        if (
          selection?.data?.ams_cm_adgroup_status === dropDownSelection[0].value
        ) {
          this.$snackbar.open({
            message: 'No new state selected for one or more entities.',
            duration: 6000,
            buttonColor: '#f5d908',
            actionText: ''
          });
        } else {
          const payload = cloneDeep(this.stateChangeRequestPayload);
          let adgroupName = null;
          let status = null;
          if (this.$store.getters.getRetailer === 'instacart') {
            adgroupName = selection?.data?.instacart_cm_adgroup_adgroup_name;
            status = selection?.data?.instacart_cm_adgroup_status;
          }
          if (this.$store.getters.getRetailer === 'samsclub_api') {
            adgroupName = selection?.data?.samsclub_api_cm_adgroup_adgroup_name;
            status = selection?.data?.samsclub_api_cm_adgroup_status;
          } else if (this.$store.getters.getRetailer === 'amazon') {
            status = selection?.data?.ams_cm_adgroup_status;
            adgroupName = selection?.data?.ams_cm_adgroup_adgroup_name;
          }

          const reqValues = {
            ':entityId': this.entityId,
            ':adgroupId': selection?.data?.adgroup_id,
            ':adgroupName': adgroupName,
            ':oldState': status,
            ':state': dropDownSelection[0].value,
            ':pageUrl': window.location.href
          };
          for (const item in reqValues) {
            deepReplaceObjectValues(payload, item, reqValues[item]);
          }
          payloadArr.push(payload);
        }
      }
      return payloadArr;
    },
    createAddGroup() {
      if (this.tabConfig?.createAdgroup) {
        return this.tabConfig.createAdgroup(this);
      }
      const that = this;
      this.$store.dispatch('campaignCreation/updateAllSetpsData', {});
      this.$router.push({
        name: 'create_ad_group',
        query: {
          key: that.$route.params.entityId + '-0',
          campaignName: this.context?.campaignInfo?.data?.name
        }
      });
    },
    handleSelectionChange(item1, item2) {
      this.tableGridAPI = item2?.api;
      this.selections = item1;
    },
    transformBid(oldBid) {
      let delta = parseFloat(this.bidValue.inputValue);
      if (this.bidValue.radioValue === 'set_new_bid') {
        return delta;
      }
      if (this.bidValue?.dropdownValue === 'percentage') {
        delta = oldBid * (delta / 100);
      }
      if (this.bidValue?.radioValue === 'increase_bid') {
        return parseFloat((oldBid + delta).toFixed(2));
      } else {
        return parseFloat((oldBid - delta).toFixed(2));
      }
    },
    onBidUpdates(updatedValues) {
      this.bidValue = updatedValues;
      const budgetForMaxCheck =
        this.campaignInfo?.data?.budget_type === 'max_impressions'
          ? this.campaignInfo?.data?.budget
          : this.campaignInfo?.data?.target_daily_budget;
      this.selections.map((sel) => {
        const oldBid = sel?.data?.instacart_cm_adgroup_default_bid || 0;
        let newBid = this.transformBid(oldBid);
        if (
          ((updatedValues?.inputValue && !newBid) ||
            parseFloat(newBid) < 0.3) &&
          this.$store.getters.getRetailer === 'instacart'
        ) {
          this.errorMessage = 'Min bid should be $0.3';
        } else if (
          ((updatedValues?.inputValue && !newBid) || parseFloat(newBid) > 85) &&
          budgetForMaxCheck > 85 &&
          this.$store.getters.getRetailer === 'instacart'
        ) {
          this.errorMessage = 'Max bid should be $85';
        } else if (
          this.$store.getters.getRetailer === 'instacart' &&
          budgetForMaxCheck < 85 &&
          ((updatedValues?.inputValue && !newBid) ||
            parseFloat(newBid) >= budgetForMaxCheck)
        ) {
          this.errorMessage = `Max bid should be less than ${
            this.campaignInfo?.data?.budget_type === 'max_impressions'
              ? 'lifetime'
              : 'daily'
          } budget of $${budgetForMaxCheck}`;
        } else {
          this.errorMessage = '';
        }
      });
    },
    openActionsWorkspace(
      actionId,
      removeSelectionState,
      selectionLength,
      dropDownSelection,
      selections
    ) {
      if (actionId === 'editStatus') {
        this.confirmStatusChange = {
          component: 'confirmStatusChangeComponent',
          props: (vueRef) => ({
            config: {
              heading: `EDIT STATUS(${vueRef.selections.length} item${
                vueRef.selections.length > 1 ? 's' : ''
              } selected)`,
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              message: `Status for selected item${
                vueRef.selections.length > 1 ? 's' : ''
              } will be ${dropDownSelection[0].value}`,
              apply: () => {
                this.callStateChange(dropDownSelection, selections);
              },
              handleCancel: () => {
                this.closeActionBar();
              }
            }
          })
        };
      } else {
        this[actionId] = true;
      }
    },
    closeActionBar() {
      this.tableGridAPI?.deselectAll();
      this.selections = [];
      this.adjustBids = null;
      this.confirmStatusChange = null;
    },
    callStateChange(dropDownSelection, selections) {
      if (this.tabConfig?.changeStatus) {
        return this.tabConfig?.changeStatus?.(
          this,
          dropDownSelection,
          selections
        );
      }
      let payloadArr = this.getPayloadArr(selections, dropDownSelection);
      if (payloadArr.length) {
        this.updateMetadataAction(payloadArr, {
          singular: 'Ad Group Status',
          plural: 'Ad Group Statuses'
        });
      } else {
        this.closeActionBar();
      }
    },
    onSaveBid() {
      const payloadArr = [];
      for (const selection of this.selections) {
        const oldBid =
          this.$store.getters.getRetailer === 'instacart'
            ? selection?.data?.instacart_cm_adgroup_default_bid || 0
            : selection?.data?.ams_cm_adgroup_default_bid || 0;
        const newBid = this.transformBid(oldBid);
        const payload = cloneDeep(this.bidChangeRequestPayload);
        let adgroupName = null;
        if (this.$store.getters.getRetailer === 'instacart') {
          adgroupName = selection?.data?.instacart_cm_adgroup_adgroup_name;
        }
        if (this.$store.getters.getRetailer === 'samsclub_api') {
          adgroupName = selection?.data?.samsclub_api_cm_adgroup_adgroup_name;
        } else if (this.$store.getters.getRetailer === 'amazon') {
          adgroupName = selection?.data?.ams_cm_adgroup_adgroup_name;
        }
        const reqValues = {
          ':entityId': this.entityId,
          ':adgroupId': selection?.data?.adgroup_id,
          ':adgroupName': adgroupName,
          ':oldBid': oldBid,
          ':newBid': newBid,
          ':pageUrl': window.location.href
        };
        for (const item in reqValues) {
          deepReplaceObjectValues(payload, item, reqValues[item]);
        }
        payloadArr.push(payload);
      }
      this.updateMetadataAction(payloadArr, {
        singular: 'Ad Group Bid',
        plural: 'Ad Group Bids'
      });
    },
    updateMetadataAction(payloadArr, entityName) {
      this.$store.dispatch(this.updateMetadata, {
        updateRequest: payloadArr,
        entityId: this.entityId,
        entityType: this.entityConfig.entityType,
        snackbar: this.$snackbar,
        entityName,
        routeData: this.$route
      });
      this.closeActionBar();
    },
    updateAllowedActions() {
      if (this.tabConfig?.allowedActions) {
        if (typeof this.tabConfig?.allowedActions === 'function') {
          this.allowedActions = this.tabConfig?.allowedActions(this);
        } else {
          this.allowedActions = this.tabConfig.allowedActions;
        }
      }
    }
  }
};
</script>

<style lang="css" scoped>
.min-height-card {
  min-height: 60vh;
}
</style>

<style lang="css">
.campaign_details_table_header_padding .header-container-cwc {
  padding: 0px;
}
</style>
